
import $ph from '@/plugins/phoenix';
import { ITableHeader, ITableOptions, ProcessStates } from '@/modules/system/types';
import { system, wallet } from '@/plugins/store';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { IOperationFull, IOperFilter, Month, OperTypeModes } from '../types';
import { TABLE_OPTIONS_DEFAULT } from '@/modules/system/store';
import { prepareTable, updateFilter } from '@/modules/system';
import $moment from 'moment'
import { WalletmelonAccess } from '@/modules/session/types';

@Component
export default class Transactions extends Vue {
  pickerDateFrom = ''
  pickerDateTo = ''
  dateFrom: string = new Date().native(false)
  dateTo: string = new Date().native(false)

  table: ITableOptions = $ph.clone(TABLE_OPTIONS_DEFAULT);

  loading = false
  mnuShowOperTypes = false
  mnuShowOperState = false
  mnuShowDateRange = false
  mnuShowOperCurrency = false
  month = 59
  x = 0
  y = 0

  @Watch('pickerDateFrom')
  onFromChange() {
    this.pickerDateTo = new Date(this.pickerDateFrom).add(1, 'MONTH').native(false)
  }

  @Watch('pickerDateTo')
  onToChange() {
    this.pickerDateFrom = new Date(this.pickerDateTo).add(-1, 'MONTH').native(false)
  }

  @Watch('table')
  onTableChanged(value: ITableOptions) {
    if (!this.loading && this.$vuetify.breakpoint.mdAndUp) {
      this.filter = updateFilter(this.filter, value);
    }
  }

  get canDownload() {
    return $ph.access(WalletmelonAccess.WalletTransactionsDownload)
  }

  get headers(): Array<ITableHeader> {
    return [
      {
        width: '180px',
        text: $ph.i18n('statement.OperReference'),
        value: 'operReference',
      },
      {
        width: '200px',
        text: $ph.i18n('statement.OperAmount'),
        value: 'operOutAmount',
        align: 'end',
      },
      {
        text: $ph.i18n('statement.OperValueDate'),
        value: 'operValueDate',
        align: 'center',
      },
      {
        text: $ph.i18n('statement.OperState'),
        value: 'operState',
        align: 'center',
      },
      {
        text: $ph.i18n('statement.OperInfo'),
        value: 'operInfo',
        align: 'end',
        sortable: false,
      },
    ];
  }

  get footerProps() {
    return { itemsPerPageOptions: system.tableStyle.rowsTableOptions };
  }

  get items(): Array<IOperationFull> {
    return wallet.operations.items;
  }

  get total(): number {
    return wallet.operations.total;
  }

  get filter(): IOperFilter {
    return wallet.filterOpers;
  }

  set filter(value: IOperFilter) {
    this.find(value);
  }

  async find(filter: IOperFilter) {
    this.loading = true;
    if (filter.offset === 0 && this.table.page !== 1) {
      this.table.page = 1;
    }
    await wallet.findOperations(filter)
    this.loading = false;
  }

  get customerNumber() {
    return wallet.customer ? wallet.customer.customerNumberPublic : ''
  }

  get customerName() {
    return wallet.customer ? wallet.customer.customerLegalName : ''
  }

  get dateFromRange(): Array<string> {
    if (this.dateTo === '') {
      return [this.dateFrom]
    } else {
      return [this.dateFrom, this.dateTo]
    }
  }

  set dateFromRange(value: Array<string>) {
    this.dateFrom = value[0]
    if (value.length > 1) {
      this.dateTo = value[1]
      this.completeRange()
    } else {
      this.dateTo = ''
    }
  }

  get dateToRange(): Array<string> {
    if (this.dateTo === '') {
      return [this.dateFrom]
    } else {
      return [this.dateFrom, this.dateTo]
    }
  }

  set dateToRange(value: Array<string>) {
    if (this.dateTo === '') {
      if (value.length > 1) {
        this.dateTo = value[1]
        this.completeRange()
      } else {
        this.dateTo = value[0]
      }
    } else {
      this.dateFrom = value[0]
      this.dateTo = ''
    }
  }

  shiftRange(value: number) {
    this.dateFrom = new Date(this.dateFrom).add(value, 'MONTH').native(true)
    this.dateTo = new Date(this.dateTo).add(value, 'MONTH').native(true)
    const filter: IOperFilter = $ph.clone(this.filter)
    filter.append = false
    filter.objectCreated = { from: this.dateFrom, till: this.dateTo }
    this.find(filter);
  }

  completeRange() {
    this.mnuShowDateRange = false
    if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
      const tmp = this.dateFrom
      this.dateFrom = this.dateTo
      this.dateTo = tmp
    }
    const filter: IOperFilter = $ph.clone(this.filter)
    filter.append = false
    filter.objectCreated = { from: this.dateFrom, till: this.dateTo }
    this.find(filter);
  }

  openDateRange(e) {
    e.preventDefault()
    this.pickerDateFrom = this.dateFrom
    this.pickerDateTo = new Date(this.dateFrom).add(1, 'MONTH').native(false)
    this.mnuShowDateRange = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowDateRange = true
    })
  }

  get operMode(): OperTypeModes {
    return OperTypeModes.Any
  }

  set operMode(value: OperTypeModes) {
    // DO NOTHING
  }

  openOperTypes(e) {
    e.preventDefault()
    this.mnuShowOperTypes = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowOperTypes = true
    })
  }

  get operState(): string {
    return this.filter.processState && this.filter.processState.length > 0 ? this.filter.processState[0] : ProcessStates.Any
  }

  set operState(value: string) {
    const filter: IOperFilter = $ph.clone(this.filter)
    filter.append = false
    filter.processState = value === ProcessStates.Any ? null : [value];
    this.find(filter);
  }

  openOperStates(e) {
    e.preventDefault()
    this.mnuShowOperState = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowOperState = true
    })
  }

  get currencies() {
    return wallet.currencies
  }

  get filterCurrency(): string | null {
    return this.filter.operCurrency && this.filter.operCurrency.length > 0 ? this.filter.operCurrency[0] : null
  }

  set filterCurrency(value: string | null) {
    const filter: IOperFilter = $ph.clone(this.filter)
    filter.append = false
    filter.operCurrency = value == null ? null : [value]
    this.find(filter);
  }

  openCurrency(e) {
    e.preventDefault()
    this.mnuShowOperCurrency = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowOperCurrency = true
    })
  }

  async csv() {
    const filter: IOperFilter = $ph.clone(this.filter)
    filter.export = true;
    filter.append = false
    filter.offset = 0
    filter.total = 0
    this.find(filter);
  }

  openPreview(operation: IOperationFull) {
    wallet.setCurrentPreview({ visible: true, operation })
  }

  get months(): Array<Month> {
    const months: Array<Month> = []
    const curYear = new Date().getFullYear()
    let from = new Date(curYear, new Date().getMonth(), 1).add(-59, 'MONTH')
    let till = new Date(from).add(1, 'MONTH').add(-1, 'DAY')

    for (let i = 0; i < 60; i++) {
      months.push({
        key: 'mn' + from.getFullYear() + '-' + from.getMonth(),
        name: from.getFullYear() === curYear ? $moment(from).format('MMMM') : $moment(from).format('MMMM') + ' ' + from.getFullYear(),
        from,
        till,
      })
      from = new Date(from).add(1, 'MONTH')
      till = new Date(from).add(1, 'MONTH').add(-1, 'DAY')
    }

    return months
  }

  selectMonth(value: Month) {
    const filter: IOperFilter = $ph.clone(this.filter)
    filter.append = false
    filter.offset = 0
    filter.total = 0
    filter.objectCreated = { from: value.from.native(false), till: value.till.native(false) }
    this.find(filter);
  }

  appendItems() {
    if (!this.loading && this.total > this.items.length) {
      const filter: IOperFilter = $ph.clone(this.filter)
      filter.append = true
      filter.offset = filter.offset + filter.limit
      this.find(filter)
    }
  }

  mounted() {
    window.scrollTo(0, 0)
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.table = prepareTable(this.table, this.filter);
    } else if (this.total === 0) {
      Vue.nextTick(() => { this.selectMonth(this.months[this.month]) })
    }
  }
}
