
import $ph from '@/plugins/phoenix';
import settings from '@/plugins/settings';
import { session, system, wallet } from '@/plugins/store';
import { Component, Vue } from 'vue-property-decorator';
import { ACCOUNT_DEFAULT, FILTER_DEFAULT_STATEMENT, STATEMENT_DEFAULT } from '../store';
import { IAccount, IStatement, IStatementFilter, StatementDateType } from '../types';

@Component
export default class AccountsStatementPrint extends Vue {
  loading = false

  account: IAccount = $ph.clone(ACCOUNT_DEFAULT)
  statement: IStatement = $ph.clone(STATEMENT_DEFAULT)

  get ph() {
    return $ph
  }

  get statementNumber() {
    if (this.account && this.$route.query.till && this.$route.query.from) {
      const till: any = this.$route.query.till
      const from: any = this.$route.query.from
      let id = '' + this.account.accountId
      while (id.length < 5) {
        id = '0' + id
      }
      id = (this.account.accountNumber || '000XXXXXXXXX').substring(0, 3) + id
      return id + '/' + from.substring(2).replace(/-/g, '') + '/' + till.substring(2).replace(/-/g, '')
    }
    return 'ERROR'
  }

  get company() {
    return settings.company
  }

  get customer() {
    return wallet.customer
  }

  get accountNumber(): string {
    return '' + (this.$route.query.account || '')
  }

  get type(): StatementDateType {
    const type = '' + (this.$route.query.type || '')
    if (type === 's') {
      return StatementDateType.Settlement
    } else if (type === 'b') {
      return StatementDateType.Booking
    } else {
      return StatementDateType.Real
    }
  }

  get openingBalance() {
    return $ph.format(this.statement.startBalance, { dec: this.account && this.account.currency ? this.account.currency.currencyPrecision : 2 })
  }

  get dtTurns() {
    return $ph.format(this.statement.turnoverDt, { dec: this.account && this.account.currency ? this.account.currency.currencyPrecision : 2 })
  }

  get ctTurns() {
    return $ph.format(this.statement.turnoverCt, { dec: this.account && this.account.currency ? this.account.currency.currencyPrecision : 2 })
  }

  get closingBalance() {
    return $ph.format(this.statement.endBalance, { dec: this.account && this.account.currency ? this.account.currency.currencyPrecision : 2 })
  }

  get tranCount() {
    return $ph.format(this.statement.transactions.length, { dec: 0 })
  }

  get transactions() {
    return this.statement.transactions
  }

  async refresh() {
    this.loading = true
    try {
      const dashboard = wallet.dashboard.find(item => item.account && item.account.accountNumber === this.accountNumber)
      if (dashboard && dashboard.account) {
        this.account = dashboard.account
        const filter: IStatementFilter = $ph.clone(FILTER_DEFAULT_STATEMENT);
        filter.accountNumber = this.accountNumber;
        filter.dateType = this.type;
        filter.range.from = '' + this.$route.query.from
        filter.range.till = '' + this.$route.query.till
        filter.hideStorno = true
        this.statement = await wallet.getStatementFull(filter) || $ph.clone(STATEMENT_DEFAULT)
      }

      this.loading = false
    } catch (err) {
      session.pushError(err)
    }
  }

  mounted() {
    system.setReportView(true)
    this.refresh()
  }
}
