
import { Vue, Component } from 'vue-property-decorator'
import $ph from '@/plugins/phoenix'
import { session, system, wallet } from '@/plugins/store'
import { CurrencyType, IBrandTerminal, ICurrency, Process2fa } from '../types';
import { EntityStates } from '@/modules/system/types';
import { IUserCustomer, User2FAModes, WalletmelonAccess } from '@/modules/session/types';
import settings from '@/plugins/settings';

@Component
export default class Settings extends Vue {
  showBaseCurrency = false

  emailShow = false
  emailCheckShow = false
  emailProcess = false
  emailNew = ''

  pwdShow = false
  pwdProcess = false
  pwdError = false
  pwdCurrent = ''
  pwdNew = ''
  pwdConfirm = ''

  sfaShow = false
  sfaMode = User2FAModes.None
  sfaProcess = Process2fa.Check

  terminals: Array<IBrandTerminal> = []

  async logout() {
    await session.userLogout()
    this.$nextTick(() => { window.location.replace(window.location.origin) })
  }

  get canChangeCurrency(): boolean {
    return $ph.access(WalletmelonAccess.WalletSettingsCurrency)
  }

  get canViewTerms(): boolean {
    return $ph.access(WalletmelonAccess.WalletSettingsTerminals)
  }

  get person() {
    return wallet.person
  }

  reloadTerminals() {
    const list: Array<IBrandTerminal> = []
    if (wallet.customer && wallet.customer.brands) {
      wallet.customer.brands.forEach(brand => {
        if (brand.terminals) {
          brand.terminals.forEach(t => {
            const trm = $ph.clone(t)
            trm.brandNumber = brand.brandNumber
            trm.brandName = brand.brandName
            trm.showkey = false
            trm.editing = false
            list.push(trm)
          })
        }
      })
    }
    this.terminals = list
  }

  get customerName() {
    return wallet.customer ? wallet.customer.customerLegalName : ''
  }

  get customer() {
    return wallet.customer
  }

  get user() {
    return session.user
  }

  get cor() {
    if (this.customer) {
      return $ph.itemLabel('Countries', this.customer.customerCountry, '??')
    } else {
      return '??'
    }
  }

  get currencies(): Array<ICurrency> {
    const currencies: Array<ICurrency> = []
    system.balanceCurrencies.forEach(balcur => {
      const curr = wallet.currIndex[balcur]
      if (curr && curr.currencyState === EntityStates.Active) {
        currencies.push(curr)
      }
    })
    currencies.sort((a, b) => a.currencyOrder - b.currencyOrder)
    return currencies;
  }

  get baseCurrency() {
    return this.currencies.findIndex(c => c.currencyCode === this.user.userBaseCurrency)
  }

  get complexError() {
    const re = /^(?=.*[0-9])(?=.*[.,!@#$%^&*])[a-zA-Z0-9.,!@#$%^&*]{8,50}$/;
    return this.pwdNew !== '' && !re.test(this.pwdNew) ? $ph.i18n('system.passwordNotComplex') : null
  }

  get confirmError() {
    return this.pwdNew === this.pwdConfirm ? null : $ph.i18n('system.passwordNotMatch')
  }

  get enabled2faLogon() {
    return session.user.mode2Fa === User2FAModes.Logon || session.user.mode2Fa === User2FAModes.LogonAndSign
  }

  get enabled2faOperation() {
    return session.user.mode2Fa === User2FAModes.Sign || session.user.mode2Fa === User2FAModes.LogonAndSign
  }

  get required2faLogon() {
    return settings.company.companyUISettings.global.required2fa === User2FAModes.Logon || settings.company.companyUISettings.global.required2fa === User2FAModes.LogonAndSign
  }

  get required2faOperation() {
    return settings.company.companyUISettings.global.required2fa === User2FAModes.Sign || settings.company.companyUISettings.global.required2fa === User2FAModes.LogonAndSign
  }

  async generateKey(term: IBrandTerminal) {
    term.editing = true
    try {
      term.terminalSecret = await session.generateTerminalKey(term.terminalNumber)
    } catch (e) {
      session.pushError(e)
    }
    term.editing = false
  }

  async changePwd() {
    this.pwdProcess = true
    this.pwdError = false;
    try {
      await session.userSetPass({ oldPwd: this.pwdCurrent, pwd: this.pwdNew })
      session.showNotification({ message: 'system.passwordChanged', type: 'info', timeout: 3000 })
      this.pwdCurrent = ''
      this.pwdNew = ''
      this.pwdConfirm = ''
      this.pwdShow = false
    } catch (error) {
      this.pwdError = true;
      session.pushError(error)
    }
    this.pwdProcess = false
  }

  get emailError() {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return this.emailNew !== '' && !re.test(this.emailNew) ? $ph.i18n('system.emailNotValid') : null
  }

  async prepareChangeEmail() {
    this.emailProcess = true
    try {
      await session.changeEmail(this.emailNew)
      this.emailShow = false
      this.emailCheckShow = true
    } catch (error) {
      session.pushError(error)
    }
    this.emailProcess = false
  }

  async changeEmail(code: string) {
    this.emailProcess = true
    try {
      await session.changeEmailCheck(code)
      this.emailCheckShow = false
      this.refreshSettings()
    } catch (error) {
      session.pushError(error)
    }
    this.emailProcess = false
  }

  refreshSettings() {
    this.$router.go(0)
  }

  enable2fa(mode: User2FAModes) {
    this.sfaShow = true
    this.sfaMode = session.user.mode2Fa === User2FAModes.None ? mode : User2FAModes.LogonAndSign
    this.sfaProcess = session.user.mode2Fa === User2FAModes.None ? Process2fa.Init : Process2fa.Change
  }

  disable2fa(mode: User2FAModes) {
    this.sfaShow = true
    this.sfaMode = session.user.mode2Fa === User2FAModes.LogonAndSign ? mode : User2FAModes.None
    this.sfaProcess = Process2fa.Change
  }

  async changeBaseCurrency(currency: string) {
    try {
      session.changeBaseCurrency(currency)
    } catch (error) {
      session.pushError(error)
    }
  }

  get linkedCustomers(): Array<IUserCustomer> {
    return wallet.linkedCustomers
  }

  async switchToCustomer(customer: IUserCustomer) {
    if (customer != null) {
      try {
        await session.userSwitchCustomer(customer.customerNumber)
      } catch (err) {
        session.pushError(err)
      }
    }
  }

  mounted() {
    this.reloadTerminals()
  }
}
