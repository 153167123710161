
import settings from '@/plugins/settings';
import { session, wallet } from '@/plugins/store';
import { Component, Vue } from 'vue-property-decorator';
import { AccountTypeModes, CurrencyType, IDashboardItem } from '../types';

@Component
export default class Prices extends Vue {
  filter = ''
  hideZeroAccounts = false

  get customerName() {
    return wallet.customer ? wallet.customer.customerLegalName : ''
  }

  get sysBaseCurrency(): string {
    return settings.company.companyBaseCurrency
  }

  get cryptoItems(): Array<IDashboardItem> {
    /* eslint-disable comma-dangle */
    return wallet.dashboard.filter(item =>
      item.currency.currencyType === CurrencyType.Crypto &&
      (
        !item.account ||
        (item.account.accountTypeMode === AccountTypeModes.CustomerCurrent)
      ) &&
      (this.filter === null || item.currencyCode.toUpperCase().includes(this.filter.toUpperCase()))
    ).sort((a, b) => a.dashboardItemOrder - b.dashboardItemOrder);
    /* eslint-enable comma-dangle */
  }

  get allowFollowCrypto(): boolean {
    return true
  }

  get fiatItems(): Array<IDashboardItem> {
    /* eslint-disable comma-dangle */
    return wallet.dashboard.filter(item =>
      item.currency.currencyType === CurrencyType.Fiat &&
      (
        !item.account ||
        (item.account.accountTypeMode === AccountTypeModes.CustomerCurrent)
      ) &&
      (this.filter === null || item.currencyCode.toUpperCase().includes(this.filter.toUpperCase()))
    ).sort((a, b) => a.dashboardItemOrder - b.dashboardItemOrder);
    /* eslint-enable comma-dangle */
  }

  get allowFollowFiat(): boolean {
    return true
  }

  get accountsItems(): Array<IDashboardItem> {
    /* eslint-disable comma-dangle */
    return wallet.dashboard.filter(item =>
      item.account &&
      (
        item.account.accountTypeMode === AccountTypeModes.CusomerReserve ||
        item.account.accountTypeMode === AccountTypeModes.CustomerProcessing
      ) &&
      (!this.hideZeroAccounts || item.account.accountBalAvail !== 0) &&
      (this.filter === null || item.currencyCode.toUpperCase().includes(this.filter.toUpperCase()))
    ).sort((a, b) => a.dashboardItemOrder - b.dashboardItemOrder);
    /* eslint-enable comma-dangle */
  }
}
