
import $ph from '@/plugins/phoenix';
import { session, wallet } from '@/plugins/store';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { prepareAccountNumber } from '..';
import { IAccount, IStatementFilter, ITransaction, Month, StatementDateType } from '../types';
import $moment from 'moment'
import { WalletmelonAccess } from '@/modules/session/types';

@Component
export default class StatementView extends Vue {
  pickerDateFrom = ''
  pickerDateTo = ''
  dateFrom: string = new Date().native(false)
  dateTo: string = new Date().native(false)
  account = ''
  accountName = ''
  accCurFilter = ''
  filterBySettle = false

  items: Array<ITransaction> = []

  mnuShowAccounts = false
  mnuShowDateRange = false

  loading = false
  month = 59
  x = 0
  y = 0

  @Watch('pickerDateFrom')
  onFromChange() {
    this.pickerDateTo = new Date(this.pickerDateFrom).add(1, 'MONTH').native(false)
  }

  @Watch('pickerDateTo')
  onToChange() {
    this.pickerDateFrom = new Date(this.pickerDateTo).add(-1, 'MONTH').native(false)
  }

  get canDownload() {
    return $ph.access(WalletmelonAccess.WalletStatementDownload)
  }

  get accountNumber() {
    return this.account && this.account.trim() !== '' ? prepareAccountNumber(this.account) : $ph.i18n('accounts.selectItem')
  }

  get accounts() {
    return wallet.accounts.filter(a => this.accCurFilter === '' || a.accountCurrency === this.accCurFilter)
  }

  get accCurrencies() {
    const currs = wallet.accounts.map(a => a.accountCurrency)
    return currs.filter((v, i) => currs.indexOf(v) === i).sort((a, b) => a.localeCompare(b))
  }

  get customerNumber() {
    return wallet.customer ? wallet.customer.customerNumberPublic : ''
  }

  get customerName() {
    return wallet.customer ? wallet.customer.customerLegalName : ''
  }

  get dateFromRange(): Array<string> {
    if (this.dateTo === '') {
      return [this.dateFrom]
    } else {
      return [this.dateFrom, this.dateTo]
    }
  }

  set dateFromRange(value: Array<string>) {
    this.dateFrom = value[0]
    if (value.length > 1) {
      this.dateTo = value[1]
      this.completeRange()
    } else {
      this.dateTo = ''
    }
  }

  get dateToRange(): Array<string> {
    if (this.dateTo === '') {
      return [this.dateFrom]
    } else {
      return [this.dateFrom, this.dateTo]
    }
  }

  set dateToRange(value: Array<string>) {
    if (this.dateTo === '') {
      if (value.length > 1) {
        this.dateTo = value[1]
        this.completeRange()
      } else {
        this.dateTo = value[0]
      }
    } else {
      this.dateFrom = value[0]
      this.dateTo = ''
    }
  }

  get filter(): IStatementFilter {
    return {
      export: false,
      append: false,
      defaultSearch: null,
      accountNumber: this.account,
      bookingNumber: '',
      bookCurrency: '',
      hideStorno: true,
      dateType: this.filterBySettle ? StatementDateType.Settlement : StatementDateType.Real,
      range: { from: new Date(this.dateFrom).native(false), till: new Date(this.dateTo).native(false) },
      offset: 0,
      limit: 0,
      node: '',
      total: 0,
      sortBy: null,
      sortOrder: null,
      nodesInfo: [],
    }
  }

  shiftRange(value: number) {
    this.dateFrom = new Date(this.dateFrom).add(value, 'MONTH').native(true)
    this.dateTo = new Date(this.dateTo).add(value, 'MONTH').native(true)
  }

  completeRange() {
    this.mnuShowDateRange = false
    if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
      const tmp = this.dateFrom
      this.dateFrom = this.dateTo
      this.dateTo = tmp
    }
  }

  openAccounts(e) {
    e.preventDefault()
    this.mnuShowAccounts = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowAccounts = true
    })
  }

  openDateRange(e) {
    e.preventDefault()
    this.pickerDateFrom = this.dateFrom
    this.pickerDateTo = new Date(this.dateFrom).add(1, 'MONTH').native(false)
    this.mnuShowDateRange = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowDateRange = true
    })
  }

  selectAccount(acc: IAccount) {
    this.account = acc.accountNumber || ''
    this.accountName = acc.accountName || ''
    this.mnuShowAccounts = false
  }

  reload() {
    this.getStatement(false)
  }

  async getStatement(doExport: boolean) {
    this.loading = true
    try {
      const filter = this.filter
      filter.export = doExport
      const statement = await wallet.getStatement(filter)
      if (statement) {
        this.items = statement
      }
    } catch (err) {
      session.pushError(err)
    }
    this.loading = false
  }

  async csv() {
    this.getStatement(true)
  }

  async print() {
    this.loading = true
    try {
      await wallet.printStatement(this.filter)
    } catch (err) {
      session.pushError(err)
    }
    this.loading = false
  }

  mounted() {
    if (this.$route.query['account']) {
      const accNum: any = this.$route.query['account']
      const acc = wallet.accounts.find(a => a.accountNumber === accNum)
      if (acc) {
        this.selectAccount(acc);
      }
      this.reload()
    }
  }

  get months(): Array<Month> {
    const months: Array<Month> = []
    const curYear = new Date().getFullYear()
    let from = new Date(curYear, new Date().getMonth(), 1).add(-59, 'MONTH')
    let till = new Date(from).add(1, 'MONTH').add(-1, 'DAY')

    for (let i = 0; i < 60; i++) {
      months.push({
        key: 'mn' + from.getFullYear() + '-' + from.getMonth(),
        name: from.getFullYear() === curYear ? $moment(from).format('MMMM') : $moment(from).format('MMMM') + ' ' + from.getFullYear(),
        from,
        till,
      })
      from = new Date(from).add(1, 'MONTH')
      till = new Date(from).add(1, 'MONTH').add(-1, 'DAY')
    }

    return months
  }

  selectMonth(value: Month) {
    this.dateFrom = value.from.native(false)
    this.dateTo = value.till.native(false)
    this.getStatement(false)
  }
}
