
import { SessionState, WalletmelonAccess } from '@/modules/session/types';
import $ph from '@/plugins/phoenix';
import settings from '@/plugins/settings';
import { session, system, wallet } from '@/plugins/store';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Dashboard extends Vue {
  get sysBaseCurrency(): string {
    return settings.company.companyBaseCurrency
  }

  get canShowTransactions() {
    return $ph.access(WalletmelonAccess.WalletTransactions)
  }

  get dashboardRows() {
    return system.dashboardRows
  }

  get loading(): boolean {
    return session.currentState !== SessionState.Authorized;
  }

  get showMiniHeaded(): boolean {
    return this.showIdentBanner || this.show2faBanner || this.showQuestBanner;
  }

  get showIdentBanner(): boolean {
    return !session.user.identComplete && $ph.access(WalletmelonAccess.WalletIdentification)
  }

  get show2faBanner(): boolean {
    return session.user.askSetup2FA && session.user.identComplete && !this.showQuestBanner;
  }

  get showQuestBanner(): boolean {
    return session.user.identComplete && session.questAsk && wallet.questionnaire !== null && $ph.access(WalletmelonAccess.WalletQuestionnaire)
  }
}
