
import { WalletmelonAccess } from '@/modules/session/types';
import { prepareTable, updateFilter } from '@/modules/system';
import { TABLE_OPTIONS_DEFAULT } from '@/modules/system/store';
import { ITableHeader, ITableOptions, ProcessStates } from '@/modules/system/types';
import $ph from '@/plugins/phoenix';
import { session, system, wallet } from '@/plugins/store';
import $moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { CurrencyType, IBrandTerminal, IProcessingFilter, IProcessingRequest, Month, POSMode, StatementDateType } from '../types';

@Component
export default class Transactions extends Vue {
  pickerDateFrom = ''
  pickerDateTo = ''
  dateFrom: string = new Date().native(false)
  dateTo: string = new Date().native(false)

  posShow = false
  posMode: POSMode = POSMode.Deposit

  table: ITableOptions = $ph.clone(TABLE_OPTIONS_DEFAULT);
  searchValue = ''

  terminals: Array<IBrandTerminal> = []
  selectedTerminal: IBrandTerminal | null = null

  loading = false
  mnuShowOperCurrency = false
  mnuShowOperState = false
  mnuShowOperMethod = false
  mnuShowDateRange = false
  mnuShowTerminals = false
  mnuShowPOS = false
  month = 59
  x = 0
  y = 0

  @Watch('pickerDateFrom')
  onFromChange() {
    this.pickerDateTo = new Date(this.pickerDateFrom).add(1, 'MONTH').native(false)
  }

  @Watch('pickerDateTo')
  onToChange() {
    this.pickerDateFrom = new Date(this.pickerDateTo).add(-1, 'MONTH').native(false)
  }

  @Watch('table')
  onTableChanged(value: ITableOptions) {
    if (!this.loading) {
      this.filter = updateFilter(this.filter, value);
      this.refresh()
    }
  }

  get isLiveAllowed(): boolean {
    return $ph.access(WalletmelonAccess.WalletProcessingLive)
  }

  get canDownload(): boolean {
    return $ph.access(WalletmelonAccess.WalletProcessingDownload)
  }

  get showCheckout(): boolean {
    return system.showCheckoutPOS && $ph.access(WalletmelonAccess.WalletProcessingPOS)
  }

  get showDepositLink(): boolean {
    return system.showLinkPOS && $ph.access(WalletmelonAccess.WalletProcessingPOS)
  }

  get showPayout(): boolean {
    return system.showPayoutPOS && $ph.access(WalletmelonAccess.WalletProcessingPOS)
  }

  get headers(): Array<ITableHeader> {
    return [
      {
        text: $ph.i18n('statement.OperReference'),
        value: 'requestId',
      },
      {
        text: $ph.i18n('payment.paymentMethod'),
        value: 'requestMethod',
      },
      {
        text: $ph.i18n('statement.OperAmount'),
        value: 'requestAmount',
        align: 'end',
      },
      {
        text: $ph.i18n('statement.OperValueDate'),
        value: 'objectCreated',
        align: 'center',
      },
      {
        text: $ph.i18n('statement.OperState'),
        value: 'requestState',
      },
      {
        text: $ph.i18n('statement.OperInfo'),
        value: 'requestInfo',
        align: 'end',
        sortable: false,
      },
    ];
  }

  get footerProps() {
    return { itemsPerPageOptions: system.tableStyle.rowsTableOptions };
  }

  get items(): Array<IProcessingRequest> {
    return wallet.processing.items;
  }

  get total(): number {
    return wallet.processing.total;
  }

  get filter(): IProcessingFilter {
    return wallet.filterProcessing;
  }

  set filter(value: IProcessingFilter) {
    wallet.setProcessingFilter(value)
  }

  async find(filter: IProcessingFilter) {
    this.loading = true;
    try {
      if (filter.offset === 0 && this.table.page !== 1) {
        this.table.page = 1;
      }
      if (!this.isLiveAllowed) {
        filter.requestTestMode = true
      }
      await wallet.findProcessing(filter)
    } catch (err) {
      session.pushError(err)
    }
    this.loading = false;
  }

  get customerNumber() {
    return wallet.customer ? wallet.customer.customerNumberPublic : ''
  }

  get customerName() {
    return wallet.customer ? wallet.customer.customerLegalName : ''
  }

  get dateFromRange(): Array<string> {
    if (this.dateTo === '') {
      return [this.dateFrom]
    } else {
      return [this.dateFrom, this.dateTo]
    }
  }

  set dateFromRange(value: Array<string>) {
    this.dateFrom = value[0]
    if (value.length > 1) {
      this.dateTo = value[1]
      this.completeRange()
    } else {
      this.dateTo = ''
    }
  }

  get dateToRange(): Array<string> {
    if (this.dateTo === '') {
      return [this.dateFrom]
    } else {
      return [this.dateFrom, this.dateTo]
    }
  }

  set dateToRange(value: Array<string>) {
    if (this.dateTo === '') {
      if (value.length > 1) {
        this.dateTo = value[1]
        this.completeRange()
      } else {
        this.dateTo = value[0]
      }
    } else {
      this.dateFrom = value[0]
      this.dateTo = ''
    }
  }

  get search(): string {
    return this.searchValue
  }

  set search(value: string) {
    this.searchValue = value
    const filter: IProcessingFilter = $ph.clone(this.filter)
    filter.defaultSearch = value
    this.filter = filter
  }

  shiftRange(value: number) {
    this.dateFrom = new Date(this.dateFrom).add(value, 'MONTH').native(true)
    this.dateTo = new Date(this.dateTo).add(value, 'MONTH').native(true)
    const filter: IProcessingFilter = $ph.clone(this.filter)
    filter.objectCreated = { from: this.dateFrom, till: this.dateTo }
    this.filter = filter
  }

  completeRange() {
    this.mnuShowDateRange = false
    if (new Date(this.dateFrom).getTime() > new Date(this.dateTo).getTime()) {
      const tmp = this.dateFrom
      this.dateFrom = this.dateTo
      this.dateTo = tmp
    }
    const filter: IProcessingFilter = $ph.clone(this.filter)
    filter.objectCreated = { from: this.dateFrom, till: this.dateTo }
    this.filter = filter
  }

  openDateRange(e) {
    e.preventDefault()
    this.pickerDateFrom = this.dateFrom
    this.pickerDateTo = new Date(this.dateFrom).add(1, 'MONTH').native(false)
    this.mnuShowDateRange = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowDateRange = true
    })
  }

  get filterMethod(): string | null {
    return this.filter.requestMethod
  }

  set filterMethod(value: string | null) {
    const filter: IProcessingFilter = $ph.clone(this.filter)
    filter.requestMethod = value
    this.filter = filter
  }

  openRequestMethod(e) {
    e.preventDefault()
    this.mnuShowOperMethod = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowOperMethod = true
    })
  }

  openPosMode(e) {
    e.preventDefault()
    this.mnuShowPOS = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowPOS = true
    })
  }

  get currencies() {
    return wallet.currencies.filter(c => c.currencyType === CurrencyType.Fiat).sort((a, b) => a.currencyCode.localeCompare(b.currencyCode))
  }

  get filterCurrency(): string | null {
    return this.filter.requestCurrency && this.filter.requestCurrency.length > 0 ? this.filter.requestCurrency[0] : null
  }

  set filterCurrency(value: string | null) {
    const filter: IProcessingFilter = $ph.clone(this.filter)
    filter.requestCurrency = value == null ? null : [value]
    this.filter = filter
  }

  openCurrency(e) {
    e.preventDefault()
    this.mnuShowOperCurrency = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowOperCurrency = true
    })
  }

  get operState(): string {
    return this.filter.requestState && this.filter.requestState.length > 0 ? this.filter.requestState[0] : ProcessStates.Any
  }

  set operState(value: string) {
    const filter: IProcessingFilter = $ph.clone(this.filter)
    filter.requestState = value === ProcessStates.Any ? null : [value];
    this.filter = filter
  }

  openOperStates(e) {
    e.preventDefault()
    this.mnuShowOperState = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowOperState = true
    })
  }

  get terminal(): IBrandTerminal | null {
    return this.selectedTerminal
  }

  set terminal(value: IBrandTerminal | null) {
    const filter: IProcessingFilter = $ph.clone(this.filter)
    filter.requestTerminal = !value ? null : value.terminalNumber;
    this.selectedTerminal = !value ? null : value;
    this.filter = filter
  }

  openTerminals(e) {
    e.preventDefault()
    this.mnuShowTerminals = false
    this.x = e.target.getBoundingClientRect().x - 10
    this.y = e.target.getBoundingClientRect().y + e.target.getBoundingClientRect().height + 10
    this.$nextTick(() => {
      this.mnuShowTerminals = true
    })
  }

  get filterTest(): boolean {
    return this.filter.requestTestMode
  }

  set filterTest(value: boolean) {
    const filter: IProcessingFilter = $ph.clone(this.filter)
    filter.requestTestMode = value
    this.filter = filter
  }

  get filterBySettle(): boolean {
    return this.filter.dateType === StatementDateType.Settlement
  }

  set filterBySettle(value: boolean) {
    const filter: IProcessingFilter = $ph.clone(this.filter)
    if (value) {
      filter.dateType = StatementDateType.Settlement
    } else {
      filter.dateType = StatementDateType.Real
    }
    this.filter = filter
  }

  async refresh() {
    const filter: IProcessingFilter = $ph.clone(this.filter)
    this.find(filter);
  }

  async csv() {
    const filter: IProcessingFilter = $ph.clone(this.filter)
    filter.export = true;
    filter.append = false
    filter.offset = 0
    filter.total = 0
    this.find(filter);
  }

  openPreview(request: IProcessingRequest) {
    wallet.setCurrentPreview({ visible: true, request, callback: this.refresh })
  }

  get months(): Array<Month> {
    const months: Array<Month> = []
    const curYear = new Date().getFullYear()
    let from = new Date(curYear, new Date().getMonth(), 1).add(-59, 'MONTH')
    let till = new Date(from).add(1, 'MONTH').add(-1, 'DAY')

    for (let i = 0; i < 60; i++) {
      months.push({
        key: 'mn' + from.getFullYear() + '-' + from.getMonth(),
        name: from.getFullYear() === curYear ? $moment(from).format('MMMM') : $moment(from).format('MMMM') + ' ' + from.getFullYear(),
        from,
        till,
      })
      from = new Date(from).add(1, 'MONTH')
      till = new Date(from).add(1, 'MONTH').add(-1, 'DAY')
    }

    return months
  }

  selectMonth(value: Month) {
    const filter: IProcessingFilter = $ph.clone(this.filter)
    filter.append = false
    filter.offset = 0
    filter.total = 0
    filter.objectCreated = { from: value.from.native(false), till: value.till.native(false) }
    this.filter = filter
  }

  appendItems() {
    if (!this.loading && this.total > this.items.length) {
      this.table.page++;
      const filter: IProcessingFilter = $ph.clone(this.filter)
      filter.append = true
      filter.offset = filter.offset + filter.limit
      this.find(filter)
    }
  }

  reloadTerminals() {
    const list: Array<IBrandTerminal> = []
    if (wallet.customer && wallet.customer.brands) {
      wallet.customer.brands.forEach(brand => {
        if (brand.terminals) {
          brand.terminals.forEach(t => {
            const trm = $ph.clone(t)
            trm.brandNumber = brand.brandNumber
            trm.brandName = brand.brandName
            trm.showkey = false
            trm.editing = false
            list.push(trm)
          })
        }
      })
    }
    this.terminals = list
  }

  openPOS(mode: POSMode) {
    this.posMode = mode
    this.posShow = true
  }

  mounted() {
    window.scrollTo(0, 0)
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.table = prepareTable(this.table, this.filter);
    } else if (this.total === 0) {
      Vue.nextTick(() => { this.selectMonth(this.months[this.month]) })
    }
    this.reloadTerminals()
    this.refresh()
  }
}
